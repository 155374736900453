




























import { namespace } from 'vuex-class';
import { Component, Watch, Vue } from 'vue-property-decorator';
import { StoresStoreActions, StoresStoreGetters } from '@/store/stores.store';
import Store from '@/models/Store.model';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';
import AxiosErrorHandlerMixin from '@/misc/AxiosErrorHandler.mixin';

const StoresStore = namespace('stores');

@Component({
  components: {
    StoreListComponent: () => import(
      /* webpackChunkName: "StoreListComponent" */
      '@/components/StoreList.component.vue'
    ),
    EditStoreComponent: () => import(
      /* webpackChunkName: "EditStoreComponent" */
      '@/components/EditStore.component.vue'
    )
  }
})
export default class StoresView extends mixins(BaseMixin, AxiosErrorHandlerMixin) {
  @StoresStore.Action(StoresStoreActions.GET_ALL)
  private getAllStoresAction!: () => Promise<Store[]>;

  @StoresStore.Getter(StoresStoreGetters.STORES)
  private stores!: Store[];

  private isLoading: boolean = false;
  private showCreationDialog: boolean = false;

  private search: string = '';

  async created() {
    try {
      this.isLoading = true;
      await this.getAllStoresAction();
    } catch (e) {
      this.handleAxiosError(e);
    } finally {
      this.isLoading = false;
    }
  }

  private async closeCreationDialog(reload: boolean) {
    this.showCreationDialog = false;
    if (reload) {
      try {
        await this.getAllStoresAction();
      } catch (e) {
        this.handleAxiosError(e);
      }
    }
  }
}
